@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
    border-right: none;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  to {
    border-color: #3fabaf;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  background-color: #f7efe5;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: none;
  font-family: "Inter", sans-serif;
}

.floating-box {
  display: flex;
  flex-direction: row;
}

.title {
  text-align: left;
  font-size: calc(10px + 2vmin);
  color: #3fabaf;
  font-weight: 600;
  width: max-content;
  padding-bottom: 15px;
}

.typed-out {
  overflow: hidden;
  border-right: 0.15em solid #3fabaf;
  width: 0;
  white-space: nowrap;
  animation: typing 2s steps(26, end) forwards, blink 0.8s infinite;
}

.description {
  text-align: left;
  text-justify: inter-word;
  font-size: calc(0.2px + 2vmin);
  color: #222222;
  font-weight: 400;
  width: 70%;
  padding-bottom: 1.5em;
}

.right-column {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 60%;
}

.left-column {
  width: 30%;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-left: 10%;
}

.profile-stack {
  float: right;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  align-items: center;
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-right: 10%;
}

.pfp-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.pfp-border {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  grid-column: 1;
  grid-row: 1;
  animation: spin 120s linear infinite;
  width: 84%;
  margin-left: auto;
  margin-right: auto;
}

.social-media-icons {
  display: flex;
  flex-direction: row;
}

a{
  text-decoration: none;
  color: #3fabaf;
  border-bottom: none;
}

.social-media-icon {
  margin-right: 20px;
  fill: #3fabaf;
  width: 30px;
  height: 30px;
  align-content: center;
  background-size: auto;
  transition: 0.2s;
}

#github {
  background: url("../assets/social-icons/github-svg.svg") no-repeat;
}

#github:hover {
  background: url("../assets/social-icons/blue/blue-github-svg.svg") no-repeat;
}

#linkedin {
  background: url("../assets/social-icons/linkedin-svg.svg") no-repeat;
}

#linkedin:hover {
  background: url("../assets/social-icons/blue/blue-linkedin-svg.svg") no-repeat;
}

#email {
  background: url("../assets/social-icons/email-svg.svg") no-repeat;
}

#email:hover {
  background: url("../assets/social-icons/blue/blue-email-svg.svg") no-repeat;
}

#resume {
  background: url("../assets/social-icons/resume-svg.svg") no-repeat;
  margin-right: 0;
}

#resume:hover {
  background: url("../assets/social-icons/blue/blue-resume-svg.svg") no-repeat;
}

@media screen and (max-width: 690px) {
  .floating-box {
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 7vh;
    padding-bottom: 7vh;
  }

  .right-column {
    margin-right: 0;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .left-column {
    float: center;
    margin-left: 0;
    width: 60%;
  }

  .title {
    text-align: center;
    font-size: 4vw;
  }

  .description {
    text-align: center;
    font-size: 3vw;
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .floating-box {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .title {
    font-size: 5vw;
    margin-top: 5%;
  }

  .description {
    margin-bottom: 10%;
  }

  .right-column {
    width: 80%;
  }

  .social-media-icon {
    margin-right: 15px;
    height: 25px;
    width: 25px;
  }

  #resume {
    margin-right: 0;
  }
}

@media screen and (min-height: 1000px) {
  .social-media-icon {
    width: 45px;
    height: 45px;
    margin-right: 30x;
  }
}
